<svelte:options tag="nifty-gateway-embed" />

<script lang="ts">
  import { onMount } from "svelte"
  import { slide } from "svelte/transition"
  import logo from "./images/ng-logo.png"
  import { API_URL, COLLECTION_BASE_URL, ITEM_DETAIL_BASE_URL } from "./constants"
  import { formatMoney, handleImageError, optimizeImage, optimizeVideo } from "./utils"
  import type { Collection, FetchCollectionDataArgs } from "./types"

  export let title: string
  export let description: string | undefined
  export let store_url: string
  export let contract_address: string
  export let password: string = ""

  let collection: Collection
  let isLoading = true
  let hasError = false

  const fetchCollectionData = async ({ storeUrl, password, contractAddress }: FetchCollectionDataArgs) => {
    let params = storeUrl ? `?store_url=${storeUrl}` : `?contract_address=${contractAddress}`

    if (password) {
      params = params + `&password=${password}`
    }

    fetch(`${API_URL}/${params}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("The project is locked or does not exist")
        }
        return response.json()
      })
      .then((json) => {
        if (json.wrong_password && json.wrong_password === true) {
          throw new Error("The project is locked")
        }
        collection = json
        isLoading = false
      })
      .catch((error) => {
        console.error(`Error fetching collection data for ${storeUrl ?? contractAddress}: ${error?.message}`)
        isLoading = false
        hasError = true
      })
  }

  onMount(() => {
    if (!store_url && !contract_address) {
      console.error(
        `Error fetching collection data: you must provide a store_url or contract_address prop on the nifty-gateway-embed element`
      )
      isLoading = false
      hasError = true
    } else if (store_url || contract_address) {
      fetchCollectionData({ storeUrl: store_url, contractAddress: contract_address, password })
    }
  })
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="true" />
  <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet" />
</svelte:head>

{#if !hasError}
  <div out:slide class="container">
    <div class="headline">
      <img class="logo" src={logo} alt="Nifty Gateway" />
      {#if title || collection?.storeName}
        <p>
          <a href={`${COLLECTION_BASE_URL}/${store_url}`} target="_blank">
            {title || collection?.storeName}
          </a>
        </p>
      {/if}
    </div>
    <div class="description">
      {#if description}
        <p>{description}</p>
      {/if}
    </div>

    <div class="card-grid">
      {#if isLoading}
        <div class="skeleton-block skeleton-effect-wave" />
        <div class="skeleton-block skeleton-effect-wave" />
        <div class="skeleton-block skeleton-effect-wave" />
      {/if}
      {#if !isLoading && collection && !hasError}
        {#each collection.nifties as nifty}
          <a href={`${ITEM_DETAIL_BASE_URL}/${collection.contractAddress}/${nifty.niftyType}`} target="_blank">
            <div class="card">
              <div class="card__asset">
                {#if nifty.niftyImageURL.endsWith("mp4")}
                  <div class="card__badge">
                    <!-- https://feathericons.com/?query=video -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-video"
                      ><polygon points="23 7 16 12 23 17 23 7" /><rect x="1" y="5" width="15" height="14" rx="2" ry="2" /></svg
                    >
                  </div>
                  <video
                    class="video"
                    alt={nifty.niftyTitle}
                    src={optimizeVideo(nifty.niftyImageURL)}
                    loop
                    playsinline
                    muted
                    on:mouseenter={(e) => e.currentTarget.play()}
                    on:mouseleave={(e) => e.currentTarget.pause()}
                  >
                    <track kind="captions" />
                  </video>
                {/if}
                <img
                  class="img"
                  alt={nifty.niftyTitle}
                  src={optimizeImage(nifty.staticThumbnailUrl ? nifty.staticThumbnailUrl : nifty.niftyImageURL)}
                  on:error={handleImageError}
                />
              </div>
              <div class="card__content">
                <p class="artist">{nifty.artistName}</p>
                <p class="nifty-title">{nifty.niftyTitle}</p>
                <p class="price">{formatMoney.format(nifty.niftyPriceInCents * 0.01)}</p>
              </div>
              <div class="card__btn">
                <span class="card__btn-text">View Details</span>
              </div>
            </div>
          </a>
        {/each}
      {/if}
    </div>
  </div>
{/if}

<style lang="scss">
  @import "./app.scss";
</style>
